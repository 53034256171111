<template>
  <div class="workBox">
    <p class="titleHd">共{{ total }}个活动</p>
    <div class="hdBox">
      <ul>
        <li v-for="item in list" :key="item.id">
          <div class="left">
            <img src="../../assets/web2x_zuoye.png" alt="" />
            作业
          </div>
          <div class="right">
            <p @click="toWorkDetail(item.id)">{{ item.title }}</p>
            <p>
              <span>提交截止时间：{{ item.close_time }}</span>
              <span>{{ item.is_over_text }}</span>
              <span>个人作业</span>
            </p>
            <p :class="item.is_submit > 0 ? 'is_submit' : 'no_submit'">
              {{ item.is_submit_text }}
              <span v-if="item.status == 1">{{
                item.score ? item.score + "分" : "尚未打分"
              }}</span>
            </p>
          </div>
          <el-button
            type="primary"
            class="subBtn"
            @click="toWorkDetail(item.id)"
            >提交作业</el-button
          >
        </li>
      </ul>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[30, 60, 80, 100]"
      :page-size="30"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { jobList } from "@/api/order.js";
export default {
  data() {
    return {
      currentPage: 1,
      list: [],
      total: 0,
      pageSize: 30,
    };
  },
  created() {
    this.getList(
      sessionStorage.getItem("gradeID"),
      this.currentPage,
      this.pageSize
    );
  },
  watch: {
    "$store.state": {
      handler(val) {
        this.getList(val.gradeId, this.currentPage, this.pageSize);
      },
      deep: true,
    },
  },
  methods: {
    getList(gradeID, page, page_size) {
      let a = JSON.parse(localStorage.getItem("userInfo"));
      jobList({
        grade_id: gradeID,
        page,
        page_size,
      }).then((res) => {
        this.list = res.data;
        this.total = res.total;
      });
    },
    // 点击去作业详情页
    toWorkDetail(id) {
      let routeData = this.$router.resolve({
        path: "/homeWork",
        query: { id },
      });
      //    query: item,
      window.open(routeData.href, "_blank");
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getList(sessionStorage.getItem("gradeID"), 1, this.pageSize);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList(
        sessionStorage.getItem("gradeID"),
        this.currentPage,
        this.pageSize
      );
      console.log(`当前页: ${val}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.workBox {
  padding-bottom: 100px;
  box-sizing: border-box;
}
.titleHd {
  font-size: 20px;
}
.hdBox {
  width: 100%;
  margin: 10px 0;
  padding-top: 16px;
  border: 1px solid #e7ebf0;
  border-radius: 8px;
  ul {
    li {
      &:last-child {
        .right {
          border: none;
        }
      }
      padding: 10px 16px 10px 16px;
      box-sizing: border-box;
      display: flex;
      position: relative;
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 85px;
        margin-right: 16px;
        font-size: 16px;
        img {
          margin-bottom: 16px;
        }
      }
      .right {
        flex: 1;
        border-bottom: 1px solid #eee;
        background: #fff;

        p {
          &:nth-child(1) {
            font-size: 20px;
            cursor: pointer;
          }
          &:nth-child(2) {
            font-size: 16px;
            color: #5f6368;
            margin-top: 10px;
            span {
              &:nth-child(2) {
                margin: 0 10px;
                display: inline-block;
                padding: 0 6px;
                box-sizing: border-box;
                border: 1px solid #5f6368;
                border-top: none;
                border-bottom: none;
              }
            }
          }
          &:nth-child(3) {
            color: #ff941f;
            font-size: 16px;
            margin: 10px 0 6px 0;
          }
        }
      }
      .subBtn {
        position: absolute;
        right: 20px;
        top: 10px;
        font-size: 16px;
      }
    }
  }
}
::v-deep .el-pagination {
  text-align: center;
}
.is_submit {
  color: #4285f4 !important;
}
.no_submit {
  color: red !important;
}
</style>
